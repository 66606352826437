import { useCallback } from 'react';

import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { SortOrder } from 'shared/enums/sort-order.enum';
import { Util } from 'app/util/util';

const presort = (loads: AvailableLoadSummary[]) =>
  loads.slice()
    .sort(Util.sortByField('number'))
    .sort(Util.sortByPathToFieldIsNotNull('totalCost', true))
    .sort(Util.sortByPathToFieldIsNotNull('binRateCostScore', true));

export const useSortLoads = (sortConfig: SortableRequest) => {
  return useCallback((unsortedLoads: AvailableLoadSummary[], ) => {

      const isDescending = sortConfig.sortOrder === SortOrder.DESC;

      if (!unsortedLoads) {
        return unsortedLoads;
      }

      if (sortConfig.skipSorting) {
        return unsortedLoads.slice();
      }
      const presortedLoads = presort(unsortedLoads);

      // when sorting by location, sort by city then state.
      if (sortConfig.sortByColumnName === 'destinationStateProvinceCode') {
        return presortedLoads.slice()
          .sort(Util.sortByPathToFieldIsNotNull('destinationCity', isDescending, false))
          .sort(Util.sortByPathToField(sortConfig.sortByColumnName, isDescending, false, false));
      } else if (sortConfig.sortByColumnName === 'originStateProvinceCode') {
        return presortedLoads.slice()
          .sort(Util.sortByPathToFieldIsNotNull('originCity', isDescending, false))
          .sort(Util.sortByPathToField(sortConfig.sortByColumnName, isDescending, false, false));
      }
      // otherwise sort by what was sent
      return presortedLoads.slice()
        .sort(Util.sortByPathToFieldIsNotNull(sortConfig.sortByColumnName, isDescending, false));
    },
    [sortConfig]
  );
};
