import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useLoadsSearchState } from 'pages/find-loads-ver2/hooks/use-loads-search-state.hook';
import { useSortLoads } from 'shared/find-loads/hooks/use-sort-loads.hook';
import { resetAvailableLoads, searchAvailableLoads } from 'shared/find-loads/redux/find-loads.actions';
import { AvailableLoadSummary, SearchCategory } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useCallback, useMemo } from 'react';

interface BestInCategoryDescriptor {
  index?: number;
  value: number;
  rate?: number;
  loadNumber?: number;
}

type BestInCategorySet = { [key in SearchCategory]: BestInCategoryDescriptor };

const searchCategoryValueSelector: { [key in SearchCategory]: (load: AvailableLoadSummary) => number | undefined | null } = {
  BestRateAllIn: l => l.totalCost,
  BestRatePerMile: l => l.distance ? l.totalCost / l.distance : null,
  ShortestDeadhead: l => l.deadheadDistance,
};

const searchCategoryValueComparer: {
  [key in SearchCategory]: (
    newCategoryValue: number | undefined | null,
    currentBest: BestInCategoryDescriptor
  ) => boolean
} = {
  BestRateAllIn: (n, c) => n > c.value,
  BestRatePerMile: (n, c) => n !== null && n !== undefined && n > c.value,
  ShortestDeadhead: (n, c) => n !== null && n !== undefined && n < c.value,
};

function categorizeLoads(loads: AvailableLoadSummary[]): AvailableLoadSummary[] {
  if (!loads?.length) {
    return;
  }
  const bestInCategory: BestInCategorySet = {
    BestRateAllIn: {
      value: 0,
    },
    BestRatePerMile: {
      value: 0,
    },
    ShortestDeadhead: {
      value: Infinity,
    }
  };

  const setBestInCategory = (category: SearchCategory, bestLoad: AvailableLoadSummary, value: number, index: number) => {
    bestInCategory[category].index = index;
    bestInCategory[category].value = value;
    bestInCategory[category].loadNumber = bestLoad.number;
    bestInCategory[category].rate = bestLoad.totalCost;
  };

  const hasLowestLoadNumber = (bestLoad: AvailableLoadSummary, category: SearchCategory) =>
    bestLoad.number < bestInCategory[category].loadNumber;

  const hasBestRate = (bestLoad: AvailableLoadSummary, category: SearchCategory) =>
    bestLoad.totalCost > bestInCategory[category].rate;

  const hasSameRate = (bestLoad: AvailableLoadSummary, category: SearchCategory) =>
    bestLoad.totalCost === bestInCategory[category].rate;

  const markIfIsBestInCategory = (category: SearchCategory, load: AvailableLoadSummary, index: number) => {
    const newCategoryValue = searchCategoryValueSelector[category](load);
    const isBestInCategoryAlreadyExist = !!bestInCategory[category].loadNumber;
    const isBestInCategory = searchCategoryValueComparer[category](newCategoryValue, bestInCategory[category]) ||
      (isBestInCategoryAlreadyExist && newCategoryValue === bestInCategory[category].value &&
        (hasBestRate(load, category) ||
          (hasSameRate(load, category) && hasLowestLoadNumber(load, category))));

    if (isBestInCategory) {
      setBestInCategory(category, load, newCategoryValue, index);
    }
  };

  loads.forEach((load, index) => {
    load.categories = undefined;
    markIfIsBestInCategory('BestRateAllIn', load, index);
    markIfIsBestInCategory('BestRatePerMile', load, index);
    markIfIsBestInCategory('ShortestDeadhead', load, index);
  });

  for (const bestInCategoryKey in bestInCategory) {
    if (bestInCategory[bestInCategoryKey].index !== undefined) {
      loads[bestInCategory[bestInCategoryKey].index].categories =
        !!loads[bestInCategory[bestInCategoryKey].index].categories
          ? [...loads[bestInCategory[bestInCategoryKey].index].categories, (bestInCategoryKey as SearchCategory)]
          : [(bestInCategoryKey as SearchCategory)];
    }
  }

  return loads;
}

function ensureFailedToBookLoadsDoesNotHaveCostsAndAreNotOfferable(
  loads: AvailableLoadSummary[],
  failedBookings: number[]
) {
  if (!failedBookings || !failedBookings.length || !loads) {
    return loads;
  }

  return loads.map(item => {
    if (!failedBookings.some(number => number === item.number)) {
      return item;
    }

    const newItem = new AvailableLoadSummary(item.toJson());
    newItem.isNotOfferable = true;
    newItem.binRateCost = null;
    return newItem;
  });
}

export const useSortedResults = (
  sortConfig,
  filteredOnBin,
  pagination,
  loadSummaries: AvailableLoadSummary[],
  shouldCategorizeLoads = false,
): [AvailableLoadSummary[], (resetLoads?: boolean) => any, number] => {
  const { performedSuggestedLoadSearch, searchCriteria } = useLoadsSearchState();
  const bookings = useSelector(state => state.findLoads.results.carrierBooks);
  const failedBookings = useSelector(state => state.findLoads.results.failedBooks);

  const processedLoads = useMemo(() => {
    const notBookedLoads = !bookings ? loadSummaries : loadSummaries?.filter(c => !bookings.some(l => l.loadNumber === c.number));
    const modifiedLoads = ensureFailedToBookLoadsDoesNotHaveCostsAndAreNotOfferable(notBookedLoads, failedBookings);
    const filteredLoads = modifiedLoads?.filter(load => filteredOnBin ? load.totalCost : load);
    return shouldCategorizeLoads && !performedSuggestedLoadSearch && !searchCriteria?.loadNumber
      ? categorizeLoads(filteredLoads)
      : filteredLoads;
  },
    [loadSummaries, bookings, failedBookings, shouldCategorizeLoads, filteredOnBin, searchCriteria, performedSuggestedLoadSearch]
  );

  const results = useMemo(
    () => {
      return { loads: processedLoads, pagination: pagination };
    },
    [pagination, processedLoads]
  );
  const sortLoads = useSortLoads(sortConfig);

  const dispatchSearchAvailableLoads = useDispatcher(searchAvailableLoads);
  const dispatchResetAvailableLoads = useDispatcher(resetAvailableLoads);
  const refreshLoads = useCallback((resetLoads?: boolean) => {
    if (!!resetLoads) {
      dispatchResetAvailableLoads();
    }
    dispatchSearchAvailableLoads(searchCriteria);
  }, [dispatchSearchAvailableLoads, dispatchResetAvailableLoads, searchCriteria]);

  const start = useMemo(() => (pagination.pageNumber - 1) * pagination.pageSize, [results, pagination]);
  const end = useMemo(() => pagination.pageNumber * pagination.pageSize, [results, pagination]);

  const [sortedLoads, totalLoads] = useMemo(() => {
    return [sortLoads(results?.loads), (results?.loads?.length ?? 0)];
  }, [results, sortLoads]);

  const loads = useMemo(
    () => sortedLoads?.slice(start, end),
    [start, end, sortedLoads, totalLoads]
  );

  return [loads, refreshLoads, totalLoads];

};
