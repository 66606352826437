import { selectByKey } from 'store/selectors/selector-utils';
import { FindLoadsResultsState } from 'shared/find-loads/redux/find-loads.reducers';

const fromFindLoadsResults: SelectorCreator<FindLoadsResultsState> = innerSelector => (s, o) => innerSelector(s.findLoads.results, o);

export function results<TOwnProps>(state: NavCarrierState, ownProps: TOwnProps) {
  return fromFindLoadsResults(resultsState => ({ results: resultsState }))(state, ownProps);
}

export namespace results {
  export const loads = fromFindLoadsResults(selectByKey('loads'));
  export const pagination = fromFindLoadsResults(selectByKey('pagination'));
  export const select = fromFindLoadsResults;
}
