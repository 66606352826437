import { selectByKey } from 'store/selectors/selector-utils';
import { FindLoadsState } from 'shared/find-loads/redux/find-loads.reducers';

import { results as resultsSelectors } from './results.selectors';

const fromFindLoads: SelectorCreator<FindLoadsState, { findLoads: FindLoadsState }> = innerSelector => (s, o) => innerSelector(s.findLoads, o);

export function select_findLoads<TOwnProps>(state: NavCarrierState & { findLoads: FindLoadsState }, ownProps: TOwnProps) {
  return fromFindLoads(findLoadsState => ({ findLoads: findLoadsState }))(state, ownProps);
}

export namespace select_findLoads {
  export const results = resultsSelectors;

  export const searchType = fromFindLoads(selectByKey('searchType'));
  export const isMicrosoftLoaded = fromFindLoads(selectByKey('isMicrosoftLoaded'));
  export const searchHistory = fromFindLoads(selectByKey('searchHistory'));
  export const searchCriteria = fromFindLoads(selectByKey('searchCriteria'));
  export const error = fromFindLoads(selectByKey('error'));
  export const searchCorrelationId = fromFindLoads(selectByKey('searchCorrelationId'));

  export const select = fromFindLoads;
}
